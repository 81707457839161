<template>
	<div class="basic">
		<div class="title" style="vertical-align: top; font-size: xx-large;">
			{{ startDate | long_date }}</div>
		<div
				 class="bigScore right"
				 :class="{'good ': score.score >= 0, 'bad ': score.score < 0}">{{ score.score }} ({{ score.numCorrect }}/{{ score.numChoices }}) {{ score.percentage }}%</div>
		<div>
			On {{ startDate.format('dddd') }} {{ startDate | long_date }} you were correct about
			{{ score.numCorrect }} of {{ score.numChoices }} {{ score.numChoices == 1 ? 'choice' : 'choices' }}
			for a score of {{ score.score > 0 ? '+' : '' }}{{ score.score }}.
			<span v-if="score.numChoices == score.numCorrect"><b>Well done!</b></span>
			Here's how your influencers affected your scores for the day.
		</div><br />

		<div class="divider">
			<div class="day-picker left" @click="goToDate(dateInfo.previous)">&lt;&lt;</div>Influencers
			<div class="day-picker right" @click="goToDate(dateInfo.next)">&gt;&gt;</div>
		</div>
		<div class="infRow" v-for="graph in infGraphs" v-bind:key="graph.name">
			<div class="feedName" :style="{'color': graph.color}">{{ graph.name }}</div>
			<div
					 class="feedScore right"
					 :class="{'good ': graph.score.score >= 0, 'bad ': graph.score.score < 0}">{{ graph.score.score }} ({{ graph.score.numCorrect }}/{{ graph.score.numChoices }}) {{ graph.score.percentage }}%</div>
			<influencer-graph :influencers="graph.influencers" :key="reload"></influencer-graph>
		</div>
		<div class="divider">Choices On {{ startDate.format('dddd') }} {{ startDate | long_date }} </div>
		<choice-row :choice="choice" v-for="choice in choices" v-bind:key="choice.id"></choice-row>
		<div class="spacer"></div>
		<div class="footer">
			<button class="goButton" @click="done()">Back</button>
		</div>
		<div class="footer-spacer" />
		<div class="bottom-spacer" />
	</div>
</template>

<script>
import InfluencerGraph from "@/components/InfluencerGraph";
import ChoiceRow from "@/components/ChoiceRow";
import * as moment from 'moment';

export default {
	name: "Date",
	components: {
		ChoiceRow,
		InfluencerGraph
	},
	watch: {
		$route: function () {
			this.loadData();
			this.loadChoices();
		}
	},
	data() {
		return {
			dateInfo: {},
			startDate: new moment(),
			score: {},
			graph: {},
			influencers: [],
			infGraphs: [],
			choices: [],
			reload: 0
		};
	},
	mounted() {
		this.loadData();
		this.loadChoices();
	},
	methods: {
		loadData: async function () {
			this.scoreLines = JSON.parse(localStorage.getItem(`date-${this.$route.params.id}-score-lines`));
			this.choices = JSON.parse(localStorage.getItem(`date-${this.$route.params.id}-choices`));
			this.infGraphs = JSON.parse(localStorage.getItem(`date-${this.$route.params.id}-inf-graphs`));
			this.reload++;
			this.startDate = moment(this.$route.params.id);
			this.startStr = this.startDate.format("YYYY-MM-DD");
			this.endStr = this.startDate.clone().add(1, "d").format("YYYY-MM-DD");
			this.dateInfo = await this.$services.getOne('score/dateInfo', this.startStr);

			this.score = await this.$services.get(`score?startDate=${this.startStr}&endDate=${this.endStr}`);
			const allFeeds = await this.$services.get("feeds");
			const scoredFeeds = [];
			const graphs = []
			let doneFeeds = 0;
			allFeeds.map(async (feed) => {
				feed.score = await this.$services.get(`score?startDate=${this.startStr}&endDate=${this.endStr}&feedID=${feed.id}`);
				const scores = await this.$services.get(`score?startDate=${this.startStr}&endDate=${this.endStr}&feedID=${feed.id}&daily`);
				if (scores.length <= 0) {
					doneFeeds++;
				} else {
					//Get the scores for the trend graph
					scoredFeeds.push({name: feed.name, color: feed.color, scores: scores});
					//Get the Influencers for their charts
					const data = await this.$services.get(`influencers/scored?startDate=${this.startStr}&endDate=${this.endStr}&feedID=${feed.id}`);
					const influencers = data.filter((influencer) => {
						return influencer.numChoices > 0;
					});
					const infChart = {name: feed.name, color: feed.color, score: feed.score, influencers: influencers};
					graphs.push(infChart);
					doneFeeds++;
				}
				if (doneFeeds == allFeeds.length) {
					graphs.sort((a, b) => a.name < b.name ? -1 : 1);
					this.infGraphs = graphs;
					this.scoreLines = scoredFeeds;
					this.reload++;
					localStorage.setItem(`date-${this.$route.params.id}-score-lines`, JSON.stringify(this.scoreLines));
					localStorage.setItem(`date-${this.$route.params.id}-inf-graphs`, JSON.stringify(this.infGraphs));
				}
			});
		},
		loadChoices: async function () {
			this.choices = await this.$services.get(`choices/filtered?startDate=${this.startStr}&endDate=${this.endStr}`);
			localStorage.setItem(`date-${this.$route.params.id}-choices`, JSON.stringify(this.choices));
		},
		goToDate(to) {
			const date = new Date(to);
			const txtDate = `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()}`;
			this.$router.push(`/date/${txtDate}`)
		},
		done() {
			this.$router.back();
		}
	}
}

</script>

<style scoped>
.day-picker {
	font-size: 24px;
}
</style>
